import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Icon, Tooltip } from '@junglescout/edna'

import {
  graphLimitFormatter,
  listingLimitFormatter,
  formatLimit
} from 'helpers/feature_limit_modal'

const Limit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
const IconWrapper = styled.div`
  display: flex;
`

export const CurrentPlanFeatures = () => {
  const { t } = useTranslation()

  const { featureLimits } = useSelector(state => state.globalData)

  const renderTooltip = isAdvanced => {
    const tooltipText = isAdvanced
      ? t(
          'upgradeModal.aiAssist.advancedTooltip',
          "Includes answers specific to your business data and Jungle Scout's data, including financial figures, advertising data, sales estimates."
        )
      : t(
          'upgradeModal.aiAssist.limitedTooltip',
          'Includes answers tailored to Jungle Scout knowledge base to easily find answers specific to using Jungle Scouts tools and selling on Amazon.'
        )

    return (
      <Tooltip content={tooltipText} size="large">
        <IconWrapper>
          <Icon width="16px" height="16px" name="TOOLTIP_QUESTION" />
        </IconWrapper>
      </Tooltip>
    )
  }

  return (
    <>
      <Limit>{t('upgradeModal.yourPlan.title', 'Your Plan')}</Limit>
      <Limit>{formatLimit(featureLimits.rankTracker)}</Limit>
      <Limit>{formatLimit(featureLimits.productTracker)}</Limit>
      <Limit>{formatLimit(featureLimits.productDatabase)}</Limit>
      <Limit>{formatLimit(featureLimits.keywordScout)}</Limit>
      <Limit>{formatLimit(featureLimits.opportunityFinder)}</Limit>
      <Limit>
        {graphLimitFormatter(featureLimits.productTracker?.maximumSelectedDays)}
      </Limit>
      <Limit>
        {graphLimitFormatter(featureLimits.keywordScout?.maximumSelectedDays)}
      </Limit>
      <Limit>
        {listingLimitFormatter(featureLimits.listingBuilder?.featureLimit)}
      </Limit>
      <Limit>
        {featureLimits.aiFeaturesChatRequests?.featureLimit === -1
          ? t('upgradeModal.aiAssist.advanced', 'Advanced')
          : t('upgradeModal.aiAssist.limited', 'Limited')}
        {renderTooltip(
          featureLimits.aiFeaturesChatRequests?.featureLimit === -1
        )}
      </Limit>
      <Limit>{formatLimit(featureLimits.aiFeatures)}</Limit>
    </>
  )
}
